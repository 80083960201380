import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/index.css";
import "default-passive-events";
import store from "./store/index";
//引入swiper样式
import "swiper/css/swiper.css";
import FilterBox from "@/components/FilterBox";

// collapse 展开折叠
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import "element-ui/lib/theme-chalk/base.css";
Vue.component(CollapseTransition.name, CollapseTransition);

Vue.config.productionTip = false;
Vue.use(ElementUI);

import VScaleScreen from "v-scale-screen";
Vue.use(VScaleScreen);

Vue.component("FilterBox", FilterBox);
Vue.config.errorHandler = function (error, vm, info) {
  console.error("Error:", error);
};

export default new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
}); //.$mount("#app")
