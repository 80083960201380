<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { setToken, getToken, setRouter, getRouter } from "@/utils/userInfo";
import router from "@/router";
export default {
  name: "App",
  data() {
    return {};
  },
  components: {},
  // created() {
  //   window.addEventListener("beforeunload", () => {
  //     console.log("页面刷新");
  //     if (getToken()) {
  //       for (let i = 0; i < getToken().length; i++) {
  //         router.addRoute(getToken()[i]);
  //       }
  //     }
  //   });
  // },
};
</script>

<style lang="less">
#app {
  min-width: 1300px;
  min-height: 100vh;
  /* background-color: #ebeced; */
}
a {
  text-decoration: none; /* 去除默认的下划线 */
  outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}
* {
  // color: #4F5470;
}
</style>
