import axios from "axios";
import { getUserInfo } from "@/utils/userInfo";
import vm from "@/main";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const requests = axios.create({
  baseURL: "https://cmc-admin.cmchj.cn",
  //baseURL: "http://localhost:8090",
  //   baseURL: "http://192.168.0.109:8090",
  //baseURL: "http://cmc-area-report-server.cmchj.cn",
  timeout: 20000,
});

//请求拦截器
requests.interceptors.request.use((config) => {
  NProgress.start();
  if (getUserInfo()) {
    config.headers.token = getUserInfo().token;
  }
  return config;
});

//相应拦截器
requests.interceptors.response.use(
  (res) => {
    NProgress.done();
    if (res.data.code == 401) {
      if (vm.$route.path !== "/login") {
        vm.$router.push("/login");
      }
      return res.data;
    } else {
      return res.data;
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default requests;
