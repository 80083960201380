import { reqLogin } from "@/api";
import { setToken, getToken, setRouter, getRouter,setUserInfo,getUserInfo } from "@/utils/userInfo";
import { filtersRouter } from "@/utils/routerList";

const state = {
  userInfo:getUserInfo()
};
const actions = {
  async userLogin({ commit }, userInfo) {
    let res = await reqLogin(userInfo);
    console.log(res);
    if (res.code == 200) {
      //储存userinfo到缓存中
      setUserInfo(res.data)
      // 存储token到缓存中
      // setToken(res.data.token);
      //储存动态路由到缓存中
      // setRouter(filtersRouter(res.data.sysMenus));
      commit("USERLOGIN", res.data);
      return 'ok';
    }else{
      return '登陆失败'
    }
  },
};
const mutations = {
  USERLOGIN(state, data) {
    state.userInfo = data;
    // state.sysMenus = filtersRouter(data.sysMenus);
    //清空路由防止重复添加
    // resetRouter()
    //动态添加路由
    // state.sysMenus.forEach(route => {
    //   router.addRoute(route)
    // });
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
