<template>
  <div class="filterBox">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="btn-box">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterBox",
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.filterBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .left {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .input-box {
      margin-bottom: 10px;
      margin-right: 30px;
      max-width: 400px;
      min-width: 250px;
      width: 22%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #4f5470;
      p {
        width: 80px;
      }
      .el-input {
        flex: 1;
      }
      .el-date-editor {
        flex: 1;
      }
      .el-select {
        flex: 1;
      }
    }
    .inputBox {
      flex: 1;
      height: 28px;
      border-radius: 4px;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      align-items: center;
      /deep/.el-input {
        width: 40%;
        border: none;
        outline: none;
        font-size: 14px;
        margin-left: 0;
        .el-input__inner {
          background: none;
          border: none;
          padding: 0;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  .btn-box {
    display: flex;
    justify-content: space-around;
  }
}
</style>
