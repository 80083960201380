//获取token
export const getToken = () => {
	return localStorage.getItem('user_token')
}

//存放token
export const setToken = (token) => {
	localStorage.setItem('user_token',token)
}

//删除token 
export const removeToken = () => {
	localStorage.removeItem('user_token')
}

//获取路由
export const getRouter = () => {
	return JSON.parse(localStorage.getItem('user_router'))
}

//存放路由
export const setRouter = (router) => {
	localStorage.setItem('user_router',JSON.stringify(router))
}

//删除路由
export const removeRouter = () => {
	localStorage.removeItem('user_router')
}

//获取userInfo
export const getUserInfo = () => {
	return JSON.parse(localStorage.getItem('user_info'))
}

//存放userInfo
export const setUserInfo = (userinfo) => {
	localStorage.setItem('user_info',JSON.stringify(userinfo))
}

//删除userInfo
export const removeUserInfo = () => {
	localStorage.removeItem('user_info')
}

